const es = {
  ssnCaptureModalHeader: 'Necesitamos su Número de Seguro Social',
  ssnCaptureModalSubHeader:
    'Para declarar impuestos y generar su W-2, se requiere su Número de Seguro Social. Si no tiene un número de seguro social, comuníquese con su gerente.',
  ssnCaptureModalInputSubLabel:
    'Al hacer clic en "Guardar", reconozco que el Número de Seguro Social que ingresé es exacto y correcto.',
  invalidSsn:
    'El número que ha ingresado no se considera un número de Seguro Social válido',
  noItin:
    'No se aceptan Números de Identificación Personal de Contribuyentes. Ingrese un número de seguro social o comuníquese con su gerente para obtener ayuda.',
  ssnCaptureSuccess: 'Su Número de Seguro Social se guardó con éxito.',
  ssnCaptureGenericError: 'Hmmm, algo salió mal. Inténtalo de nuevo.',
  ssnExistsError: 'Ya existe un usuario con este Número de Seguro Social',
  socialSecurityNumber: 'Número de Seguro Social',
  save: 'Guardar'
}

export default es
