const en = {
  ssnCaptureModalHeader: 'We need your Social Security Number',
  ssnCaptureModalSubHeader:
    'In order to file taxes and generate your W-2, your Social Security Number is required. If you do not have a Social Security Number, please contact your manager.',
  ssnCaptureModalInputSubLabel:
    'By clicking "Save", I acknowledge that the Social Security Number I entered is accurate and correct.',
  invalidSsn:
    'The number you have entered is not considered a valid Social Security Number',
  noItin:
    'Individual Taxpayer Identification Numbers are not accepted. Please enter a Social Security Number or contact your manager for assistance.',
  ssnCaptureSuccess: 'Your Social Security Number was successfully saved.',
  ssnCaptureGenericError: 'Hmmm something went wrong. Please try again.',
  ssnExistsError: 'A user with this Social Security Number already exists',
  socialSecurityNumber: 'Social Security Number',
  save: 'Save'
}

export default en
