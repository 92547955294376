import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'

import ApplicationRoot from './app/ApplicationRoot'
import { domElementGetter } from '@toasttab/ec-layout'
import './index.css'

JavascriptTimeAgo.addDefaultLocale(en)

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-ec-dash',
  rootComponent: ApplicationRoot,
  domElementGetter: domElementGetter.application,
  portalContainers: ['banquetPortalsContainer']
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'ec-dashboard-spa'
