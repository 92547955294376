import * as React from 'react'
import * as sdk from 'launchdarkly-js-client-sdk'
import { useQuery } from 'react-query'
import { ToastUser } from '@local/ec-app'
import { useConfig, useCurrentUser } from '../hooks'
import { useWindowProvider } from './window'

const initialize = (
  user: ToastUser,
  launchDarklyClientKey: string,
  window: Window
) => {
  if (!window.LDPromise) {
    window.LDPromise = new Promise<sdk.LDClient>((resolve) => {
      let client: any
      if (user && user.userUuid) {
        client = sdk.initialize(launchDarklyClientKey, {
          key: user.email,
          email: user.email.toLowerCase(),
          custom: { viewCompanyCode: user.client.toLowerCase() }
        })
      } else {
        client = sdk.initialize(launchDarklyClientKey, {
          key: Date.now().toString(),
          anonymous: true
        })
      }

      client.on('ready', function () {
        resolve(client)
      })
    })
  }
  return window.LDPromise
}

const defaultLDClient = ({} as unknown) as sdk.LDClient

export const FeaturesContext = React.createContext<sdk.LDClient>(
  defaultLDClient
)

export const FeaturesProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const window = useWindowProvider()
  const user = useCurrentUser()
  const { launchDarklyClientKey } = useConfig()
  const { data: client = defaultLDClient } = useQuery(
    'launch-darkly-client',
    () => initialize(user, launchDarklyClientKey, window),
    { suspense: true }
  )

  return (
    <FeaturesContext.Provider value={client}>
      {children}
    </FeaturesContext.Provider>
  )
}
