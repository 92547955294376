import * as React from 'react'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import Checklist from './components/Checklist'
import {
  DataType,
  PAYROLL_CARD_DESC,
  PAYSTUBS_CARD_DESC,
  Task,
  TIMESHEETS_CARD_DESC
} from './constants'
import { NotificationSection } from './components/NotificationSection'
import {
  getChecklistNavButton,
  getOnboardingStage,
  getTaskList,
  getUnreadCount,
  markTaskComplete,
  OnboardingStage
} from './data_helpers'
import { NotificationIcon } from './components/NotificationIcon'
import {
  DashboardCard,
  LOADING_DASHBOARD_CARD
} from './components/DashboardCard'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { track } from '@local/tracking'
import {
  dashboardRoute,
  dashboardRouteArgs,
  getSsnCaptureRequirement,
  SsnCaptureStatus
} from './resources/dashboardResource'
import {
  DollarIcon,
  PayrollIcon,
  TimeClockIcon
} from '@toasttab/buffet-pui-icons'

import { Tab, TabList, Tabs } from '@toasttab/buffet-pui-tabs'
import { useFeatureFlag, useI18n, useCurrentUser } from '@local/ec-app'
import { SsnCaptureModal } from './components/SsnCaptureModal'
import en from './../locales/en-US'
import es from './../locales/es'
import { AlertSection } from './components/Alerts/AlertSection'
import { AlertContents } from './components/Alerts/alerts'
import { fetchAlerts } from './resources/dashboardResource'
import { parseAlerts } from './components/Alerts/alerts'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

const useAlerts = (
    setAlerts: React.Dispatch<React.SetStateAction<AlertContents[] | undefined>>,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>,
    customerCode: string,
    taxCenterEnabled: boolean,
    isHrPlusOrAbove: boolean
) => {
  useQuery({
    queryKey: ['alerts', customerCode],
    queryFn: async () => {
      fetchAlerts()
          .then((response: any) => {
            const newAlerts = parseAlerts(
                response,
                customerCode
            )
            setAlerts(newAlerts)
          })
          .catch(() => {
            setError('Error loading alerts')
          })
    },
    // only fetch alerts if the tax center is not enabled
    enabled: !taxCenterEnabled && isHrPlusOrAbove
  })
}

export const App = () => {
  const [showNotificationsMobile, setShowNotificationsMobile] = useState(false)
  const [mobileTabIndex, setMobileTabIndex] = useState(0)
  const [data, setData] = useState<DataType>()
  const [error, setError] = useState<string>()
  const [loadingTaskId, setLoadingTaskId] = useState('')
  const [showSsnCaptureModal, setShowCaptureSsnModal] = useState<boolean>(false)
  const [alerts, setAlerts] = useState<AlertContents[]>()

  const { isUserSuperAdmin, isUserSuperAdminLight, isUserHrPlusOrDerivative } =
    useCurrentUser()

  const isAdmin = isUserSuperAdmin || isUserSuperAdminLight
  const { client: customerCode } = useCurrentUser()

  const { addResourceBundle } = useI18n()

  addResourceBundle('en-US', 'dashboard', en)
  addResourceBundle('es', 'dashboard', es)

  const isPaymentMethodActive = useFeatureFlag(
    'ec-hr-pay-preferences-enabled',
    true
  )
  const echrSpfW4IsOn = useFeatureFlag('ec-hr-spf-w4', true)
  const isPONCEnabled = useFeatureFlag(
    // (New) Payroll Onboarding Checklist, PX / POOP release
    'ec-poop-show-payroll-onboarding-checklist',
    false
  )

  const taxCenterEnabled = useFeatureFlag('ec-px-enable-tax-center', false)
  const isHrPlusOrAbove = isUserHrPlusOrDerivative || isAdmin

  const {
    canManageTodos,
    canAddPayrollTodo,
    canViewProcessPayroll,
    canViewTimesheets,
    canViewOnboardingChecklist,
    canViewEmployerChecklist
  } = data?.permissions || {}
  const preferredName = data && data.preferredName
  const onboardingStage = data && getOnboardingStage(data)
  const onboardingComplete =
    onboardingStage === OnboardingStage.DONE ||
    onboardingStage === OnboardingStage.HIDDEN
  const { payrollDashboardUrl, paystubsUrl } = data?.links || {}

  useEffect(() => {
    fetch(dashboardRoute(), dashboardRouteArgs)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response.statusText)
        }
      })
      .then((data: DataType) => {
        if (data && Object.keys(data).length) {
          setData({ ...data, employerChecklist: data.employerChecklist || {} })
        }
      })
      .catch((error) => {
        setError('Error loading dashboard')
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (!isAdmin) {
      getSsnCaptureRequirement().then((res: SsnCaptureStatus) =>
        setShowCaptureSsnModal(res && res.status)
      )
    }
  }, [])

  useEffect(() => {
    if (canViewOnboardingChecklist && onboardingComplete) {
      track('OnboardingComplete')
    }
  }, [canViewOnboardingChecklist, onboardingComplete])

    useAlerts(setAlerts, setError, customerCode, taxCenterEnabled, isHrPlusOrAbove)

  if (error) {
    return (
      <div className='m-5' data-testid='dashboard-error'>
        <Alert className='w-full' variant='error'>
          {error}
        </Alert>
      </div>
    )
  }

  const setTaskState = (taskId: string, complete: boolean) => {
    setLoadingTaskId(taskId)
    fetch(
      `/${customerCode}/dashboard/setTaskState/${taskId}/${
        complete ? 'true' : 'false'
      }`,
      {
        method: 'POST',
        credentials: 'same-origin'
      }
    ).then((res) => {
      if (!data || onboardingComplete) {
        return
      }
      setLoadingTaskId('')
      if (res.ok) {
        markTaskComplete(taskId, complete, data, setData)
      }
    })
  }

  const tasks: Task[] | undefined = getTaskList(
    setTaskState,
    data,
    loadingTaskId,
    isPaymentMethodActive,
    echrSpfW4IsOn
  )

  const desktopHeading = (
    <>
      <div
        className='hidden pb-8 mb-8 font-semibold border-b-4 md:block type-headline-1'
        data-testid='welcome-msg-dw'
      >
        {`Welcome${preferredName ? ', ' + preferredName : ''}`}
      </div>
    </>
  )

  const mobileHeading = (
    <div
      className={`${
        showNotificationsMobile ? 'hidden' : ''
      } md:hidden p-6 flex type-headline-3 font-semibold`}
      data-testid='mobile-heading'
    >
      <span className='flex-1'>Welcome</span>
      <NotificationIcon
        alertCount={getUnreadCount(data && data.notifications)}
        onClick={() => setShowNotificationsMobile(!showNotificationsMobile)}
      />
    </div>
  )

  const notificationSectionClass = showNotificationsMobile ? '' : 'hidden'
  const hideCardsOnMobile =
    showNotificationsMobile || (mobileTabIndex === 0 && !onboardingComplete)
  const hideChecklistOnMobile = showNotificationsMobile || mobileTabIndex === 1

  const isLoading = !data

  // Either a loading card or null, depending on page state
  const conditionalLoadingCard = isLoading ? LOADING_DASHBOARD_CARD : null

  const mobileTabs = canViewOnboardingChecklist && !onboardingComplete

  const showOnboardingChecklist =
    !isLoading &&
    !onboardingComplete &&
    canViewOnboardingChecklist &&
    ((isPONCEnabled && !data.isEmployeeZero) || !isPONCEnabled) // PONC replaces this checklist.

  const areAlertsLoading = !taxCenterEnabled && isHrPlusOrAbove && alerts === undefined
  const isLoadingMainPanel = isLoading || areAlertsLoading

  return (
    <div className='md:flex'>
      <SsnCaptureModal
        isOpen={showSsnCaptureModal}
        onClose={() => setShowCaptureSsnModal(false)}
      />
      {mobileHeading}
      {mobileTabs && (
        <div className={showNotificationsMobile ? 'hidden' : 'md:hidden'}>
          <Tabs defaultIndex={mobileTabIndex} onChange={setMobileTabIndex}>
            <TabList className='w-full px-6'>
              <Tab>Getting Started</Tab>
              <Tab>Dashboard</Tab>
            </TabList>
          </Tabs>
        </div>
      )}
      {showOnboardingChecklist ? (
        <div
          className={`${
            hideChecklistOnMobile ? 'hidden' : ''
          } md:block p-6 md:w-1/4`}
          style={{
            minWidth: '300px'
          }}
        >
          <Checklist
            tasks={tasks}
            isEmployerChecklist={onboardingStage === OnboardingStage.EMPLOYER}
            setTaskState={setTaskState}
          />
          {getChecklistNavButton(data, setTaskState)}
        </div>
      ) : null}
      <div className='flex-1 pt-6'>
        {desktopHeading}
        <div className='lg:flex'>
          <div className='lg:w-2/3 lg:pr-12 relative'>
            {isLoadingMainPanel ? (
              <MerryGoRound className='pin-center' size='md' />
            ) : (
              <>
                {!taxCenterEnabled && isHrPlusOrAbove ? (
                  <AlertSection alerts={alerts} />
                ) : null}
                <div className={`${notificationSectionClass} md:block`}>
                  <NotificationSection
                    notifications={data ? data.notifications : undefined}
                    onboardingComplete={onboardingComplete}
                    isHrPlus={canViewEmployerChecklist}
                    showAddTodoBtn={canManageTodos}
                    showAddPayrollTodoBtn={canAddPayrollTodo}
                    onClickBack={() => setShowNotificationsMobile(false)}
                  />
                  <div className='text-center type-default'>
                    <a
                      href='/Dashboard/ToDoArchive.aspx'
                      className='cursor-pointer text-link'
                      data-testid='archived-notifications'
                    >
                      Archived Notifications
                    </a>
                    {canManageTodos ? (
                      <a
                        href={`/mvc/${customerCode}/Dashboard/Root/ToDoReport`}
                        className='pl-2 ml-2 border-l cursor-pointer text-link'
                        data-testid='todo-report'
                      >
                        TO DO Report
                      </a>
                    ) : null}
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className={`${hideCardsOnMobile ? 'hidden' : ''} flex-1 md:block`}
          >
            {canViewProcessPayroll && payrollDashboardUrl ? (
              <DashboardCard
                title='Payroll'
                icon={<DollarIcon />}
                description={PAYROLL_CARD_DESC}
                btnText='View payroll'
                url={payrollDashboardUrl}
                testId='payroll-card'
              />
            ) : (
              conditionalLoadingCard
            )}
            {canViewTimesheets ? (
              <DashboardCard
                title='Timesheets'
                icon={<TimeClockIcon />}
                description={TIMESHEETS_CARD_DESC}
                btnText='View timesheets'
                url={`/mvc/${customerCode}/Management/TimeAndLabor/Timesheets`}
                testId='timesheets-card'
              />
            ) : (
              conditionalLoadingCard
            )}
            {paystubsUrl ? (
              <DashboardCard
                title='Paystubs'
                icon={<PayrollIcon />}
                description={PAYSTUBS_CARD_DESC}
                btnText='View paystubs'
                url={paystubsUrl}
                testId='paystubs-card'
              />
            ) : (
              conditionalLoadingCard
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
